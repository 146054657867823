/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* loader */

.lApp-header {
  font-family: arial;
  font-size: 24px;
  margin: 25px;
  width: 350px;
  height: 200px;
  outline: dashed 1px black;
  /* Setup */
  position: relative;
}

.welcome-msg{
  /* Center vertically and horizontally */
  position: absolute;
  top: 30%;
  left: 50%;
  margin: -25px 0 0 -85px; /* Apply negative top and left margins to truly center the element */
}

.loader {
  width: 75px;
  height: 75px;
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px; /* Apply negative top and left margins to truly center the element */
}

.loader-img{
  width: 50px;
  height: 60px;
  /* Center vertically and horizontally */
  position: absolute;
  top: 40%;
  left: 50%;
  margin: -20px 0 0 -25px; /* Apply negative top and left margins to truly center the element */
}