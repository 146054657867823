/* Hide scrollbar for Chrome, Safari and Opera */
.main-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.main-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.sidebar{
  position: fixed;
  width: 4.5rem;
  height: 100vh;
  /* color: white; */
  /* background-color: white;  */
  /* background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(225,225,225,1)  90%); */
  background: linear-gradient(180deg, #0A66C2 0%, #13294A  90%);
  border-right: 1px solid linear-gradient(to bottom, #000000, #3b3b3b, #777777, #b9b9b9, #ffffff);
}

.sidebar-icon>li{
  transition: 0.5s;
}
.sidebar-icon>li:hover{
  font-size: 2rem;
  transition: 0.5s;
  
}
.sidebar-icon>li>a>i{
  color: white;
}
.sidebar-icon>li:hover a>i{
  color: whitesmoke
}
/* @media(min-width: 767px){
.sidebar{
  display: flex;
}
}

@media(max-width:767px){
  .sidebar{
  display: none;
  }
} */
